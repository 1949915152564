import React, { useState } from 'react';
import { MainNavComponent, AboutUsSubNav, ProductsSubNav, MobileNavComponent } from './MobileNav.styles.js';
import { SearchButton } from '../../MainNav.styles';
import Icon from 'components/shared/Icon';
import Link from 'components/shared/Link';
import MainNavAboutUsLink from '../MainNavAboutUsLink';
import MainNavProductLink from '../MainNavProductLink';
import { isServer } from '@sitecore-jss/sitecore-jss';

import Hamburger from '../Hamburger';

import NavLink from '../NavLink';
import MainNavRibbon from '../MainNavRibbon';
import { underlineText } from 'helpers/stringHelpers';

import Overlay from 'components/shared/Overlay';

import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const MobileNav = ({ headerContext = {}, sitecoreContext: { globalContent, route }, search, setSearch, className, ...props }) => {


  if (headerContext) {
    if (Array.isArray(headerContext.globalContent)) {
      globalContent = [...globalContent, ...headerContext.globalContent];
    } else if (typeof headerContext.globalContent === 'object' && headerContext.globalContent !== null) {
      globalContent = {...globalContent, ...headerContext.globalContent};
    } else {
      const globalContent = [headerContext.globalContent];
    }
  }


  const links = globalContent?.navigation;
  const mobileUtilityLinks = globalContent?.mobileUtilityLinks;
  const logo = globalContent?.logo;
  const homePageLink = globalContent?.homepageLink;
  const linkColumns = globalContent?.footer?.linkColumns;
  const languageSelector = globalContent?.languageSelectorItems;
  const navigation = globalContent?.navigation;
  const [subNav, setSubNav] = useState(false);
  const [mobileNav, setMobileNav] = useState(false);

  const ProductsText =
    Array.isArray(links) &&
    links.find(
      ({ type, cards }) =>
        type === 'megamenu-cards' && cards && cards.filter(({ type }) => type === 'product').length > 0
    )?.text;

  const supportingLinks = navigation?.reduce((acc, el) => {
    if (el?.supportingLinks?.length) {
      return el.supportingLinks;
    }
    return [...acc];
  }, []);

  const handleLanguageSelector = (newLangCode) => {
    if (!newLangCode) return;

    if (!isServer()) {
      let splitPath = window.location.pathname.split('/');
      splitPath[1] = newLangCode;
      return (window.location.href = splitPath.join('/'));
    }
  };

  return (
    <>
      <MainNavComponent className={`NAV_WRAP mobile-nav-${className}`} search={search}>
        <Hamburger
            className={`hamburger ${mobileNav ? 'active' : ''}`}
          active={mobileNav}
          onClick={() => {
            setMobileNav((prevState) => !prevState);
          }}
        />
        <div className="logo">
          <Link href={homePageLink}>
            {/* eslint-disable-next-line jsx-a11y/alt-text */}
            <img src={logo?.src} alt={logo?.alt} />
          </Link>
        </div>

        <div className="nav-end">
          <>
            <SearchButton
              id="search-button"
              className="search-button"
              aria-label="Open Search"
              title="Open Search"
              disabled={route?.name === 'search'}
              onClick={() => {
                setSearch((prevState) => !prevState);
              }}
            >
              <Icon className="search" icon={search ? 'cross' : 'search'} size="lg" />
            </SearchButton>
          </>
        </div>

        <MobileNavComponent active={mobileNav} className={`mobile-content-${className}`}>
          <ul>
            {Array.isArray(links) &&
              links.map(({ path, title, type, cards }, index) => {
                if (type && type === 'megamenu-cards') {
                  if (cards && cards.filter(({ type }) => type === 'product').length > 0) {
                    return (
                      <li key={`${index}-${title}`}>
                        <NavLink
                          text={title}
                          subNav
                          mobile
                          active={subNav === 'products' ? 'true' : 'false'}
                          onClick={(event) => {
                            event.preventDefault();
                            if (subNav === 'about-us') {
                              setSubNav('products');
                              return;
                            }
                            if (subNav === 'products') {
                              setSubNav(false);
                              return;
                            }
                            setSubNav('products');
                          }}
                        />
                      </li>
                    );
                  }
                  if (cards.filter(({ type }) => type === 'page').length > 0) {
                    return (
                      <li key={`${index}-${title}`}>
                        <NavLink
                          text="About us"
                          subNav
                          mobile
                          active={subNav === 'about-us' ? 'true' : 'false'}
                          onClick={(event) => {
                            event.preventDefault();
                            if (subNav === 'products') {
                              setSubNav('about-us');
                              return;
                            }
                            if (subNav === 'about-us') {
                              setSubNav(false);
                              return;
                            }
                            setSubNav('about-us');
                          }}
                        />
                      </li>
                    );
                  }
                }

                return (
                  <li key={`${index}-${title}`}>
                    <NavLink mobile text={title} path={path} />
                  </li>
                );
              })}
            {Array.isArray(languageSelector) && languageSelector.length
              ? languageSelector.map(({ text, code }, index) => {
                  return (
                    <li key={`languageSelector-${index}-${text}`}>
                      <NavLink mobile text={text} onClick={() => handleLanguageSelector(code)} />
                    </li>
                  );
                })
              : null}
            {Array.isArray(mobileUtilityLinks) &&
              mobileUtilityLinks.map(({ title, path, href }, index) => (
                <li key={`${index}-${title}`}>
                  <NavLink mobile text={title} path={path} href={href} />
                </li>
              ))}
          </ul>

          <section className="sub-links">
            {Array.isArray(linkColumns) &&
              linkColumns.map(({ title, links }, index) => {
                return (
                  <div key={`${index}-${title}`}>
                    <h1>{title}</h1>
                    <ul>
                      {links.map(({ path, href, title, external }, index) => (
                        <li key={`${index}-${title}`}>
                          <Link href={external ? href : path}>{title}</Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                );
              })}
          </section>

          <ProductsSubNav active={subNav === 'products'}>
            <div className="back-button">
              <button
                onClick={() => {
                  setSubNav(false);
                }}
              >
                <Icon icon="chevron-right" size="lg" />
                Products
              </button>
            </div>
            <div className="container">
              <h1
                dangerouslySetInnerHTML={{
                  __html: ProductsText
                    ? underlineText(ProductsText, {
                        openTag: '<u>',
                        closeTag: '</u>',
                      })
                    : '',
                }}
              />
              <div className="product-list">
                {Array.isArray(links) &&
                    links
                        .find(
                            ({type, cards}) =>
                                type === 'megamenu-cards' &&
                                cards &&
                                cards.filter(({type}) => type === 'product').length > 0
                        )
                        ?.cards.map(({title, image, link}, index) => {
                      if (image && image.src) {
                        return (
                            <MainNavProductLink
                                key={`${index}-${title}`}
                                productName={title}
                                productImage={image.src}
                                buttonText={link?.title}
                                path={link?.path}
                                mobile
                            />
                        );
                      }
                      return null;
                    })}

              </div>
            </div>
            {supportingLinks?.length && <MainNavRibbon supportingLinks={supportingLinks}/>}
          </ProductsSubNav>

          <AboutUsSubNav active={subNav === 'about-us'}>
            <div className="back-button">
              <button
                onClick={() => {
                  setSubNav(false);
                }}
              >
                <Icon icon="chevron-right" size="lg" />
                About Us
              </button>
            </div>

            <div className="card-list">
              {Array.isArray(links) &&
                  links
                      .find(
                          ({ type, cards }) =>
                              type === 'megamenu-cards' &&
                              cards &&
                              cards.filter(({ type }) => type === 'page').length > 0
                      )
                      ?.cards.map(({ title, image, alt, link }, index) => {
                    const imageSrc = image?.src;
                    return (
                        imageSrc && (
                            <MainNavAboutUsLink
                                key={`${index}-${title}`}
                                text={title}
                                src={imageSrc}
                                path={link?.path}
                            />
                        )
                    );
                  })}
            </div>
          </AboutUsSubNav>
        </MobileNavComponent>
      </MainNavComponent>
      {mobileNav && <Overlay id="overlay" onClick={() => setMobileNav(false)} />}
    </>
  );
};

export default withSitecoreContext()(MobileNav);